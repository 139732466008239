import React, { useEffect } from 'react';

import Page from '../../organisms/Page';
import ContentsWrapper from '../../Layout/ContentsWrapper';
import Markdown from '../../atoms/Markdown';
import { H2 } from '../../atoms/Text';

import { useLangStore } from '../../../store';
import { Img, StyledColumns } from './styles';
import { format } from 'date-fns';
import { capitaliseFirstLetter } from '../../../helpers/string';
import { enGB, fr } from 'date-fns/locale';
import { useQueryParam } from '../../../hooks/utility';
import config from '../../../../config';

const PostPage = ({
  pageContext: {
    post: { title_en, title_fr, body_en, body_fr, published_at, excerpt_en, excerpt_fr, image },
  },
  ...pageData
}) => {
  const [queryLang] = useQueryParam('lang', pageData.location, '');
  const lang = useLangStore(store => store.currentLang);
  const setLang = useLangStore(store => store.setCurrentLang);
  const locale = lang === 'fr' ? fr : enGB;
  const prettyDate = capitaliseFirstLetter(
    format(new Date(published_at), lang === 'fr' ? 'cccc, d MMMM yyyy' : 'cccc, do MMMM yyyy', { locale }) ||
      '',
  );
  const body = lang === 'en' ? body_en : body_fr;
  const title = lang === 'en' ? title_en : title_fr;
  const excerpt = lang === 'en' ? excerpt_en : excerpt_fr;

  useEffect(() => {
    if (queryLang && queryLang !== lang) {
      setLang(queryLang);
    }
  }, []);

  return (
    <Page title={{ en: title_en, fr: title_fr }} metaImg={`${config.baseUrl}/${image?.publicURL}`} backTo="/posts" data={pageData}>
      <ContentsWrapper>
        <StyledColumns>
          <div>
            <H2>{title}</H2>
            <Img src={image?.publicURL} alt="" />
          </div>
          <Markdown>{`### ${prettyDate}\n\n*${excerpt || ''}*\n\n${body}`}</Markdown>
        </StyledColumns>
      </ContentsWrapper>
    </Page>
  );
};

PostPage.propTypes = {};

export default PostPage;
