import styled from 'styled-components';
import { Columns } from '../about/styles';
import { MarkdownWrapper } from '../../atoms/Markdown/styles';
import { mediaQuery, spacing } from '../../../assets/theme';

export const Img = styled.img`
  max-width: 100%;
  /* background-image: url(${props => props.src}); */
  /* background-size: cover; */
  margin-bottom: ${spacing[3]}px;
`;

export const StyledColumns = styled(Columns)`
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 5fr;

  ${mediaQuery.lessThan('lg')`
    grid-template-columns: 1fr;
  `};

  & ${Img} {
    grid-column-start: 1;

    ${mediaQuery.lessThan('lg')`
      padding: ${spacing[3]}px;
    `};
    ${mediaQuery.lessThan('md')`
      padding: ${spacing[3]}px 0;
      width: 100%;
      max-height: 33vh;
      object-fit: cover;
    `};
  }

  & > h2 {
    grid-column-start: 1;
  }

  & > ${MarkdownWrapper} {
    grid-column-start: 2;
    grid-row-start: 1;

    ${mediaQuery.lessThan('lg')`
      grid-column-start: 1;
      grid-row-start: auto;
    `};
  }
`;
