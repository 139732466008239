import { useCallback, useEffect, useState } from 'react';

const windowGlobal = typeof window !== 'undefined' && window;

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue(v => !v);
  }, []);
  return [value, toggle];
};

export const useQueryParam = (key, location, defaultValue = null) => {
  const search = windowGlobal?.history?.state?.path?.split('/');
  const urlQuery = search ? search[search.length - 1] : '';

  const [queryParam, setQueryParam] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get(key) || defaultValue;
  });

  useEffect(() => {
    const params = new URLSearchParams(urlQuery);
    if (queryParam && params.has(key)) {
      console.log(`has: ${key}`);
      console.log(`SETTING: ${key}: ${queryParam}`);
      params.set(key, queryParam);
    } else if (queryParam) {
      console.log(`APPENDING: ${key}: ${queryParam}`);
      params.append(key, queryParam);
    } else {
      console.log(`DELETEING: ${key}`);
      params.delete(key);
    }
    const newUrl = `${location.pathname}?${params.toString()}`;

    if (!windowGlobal) return;

    windowGlobal.history.replaceState({ path: newUrl }, '', newUrl);
  }, [key, queryParam]);

  return [queryParam, setQueryParam];
};
